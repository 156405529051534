import React, { Component } from 'react';
import Loadable from 'react-loading-overlay';
import 'video-react/dist/video-react.css';
import apiClient from '../util/api-client';
import iconCam from "images/icon-www.png";
import iconPcAndMobile from "../ui/images/iconPc_Mobile.svg";
import iconPc from "../ui/images/iconPc.svg";
import iconMobile from "../ui/images/iconMobile.svg";
import iconMobileSmall from "../ui/images/iconMobileSmall.svg";
import ListUrlErrorsUpload from './ListUrlErrorsUpload';
import AccountLoginSettings from './AccountLoginSettings';
import AdvancedSettings from './AdvancedSettings';
import TableUrlToCapture from './TableUrlToCapture';
import ToggleSectionOfCaptureUrls from './ToggleSectionOfCaptureUrls';
import popupWhenCaptureUrls from '../util/popup_when_capture_urls';

const regex = new RegExp("^(http[s]?:\\//){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
const MAX_IN_SETTING = 10;
const MAX_URLS = 30;

const deviceOptions = [
  {
    icon: iconPcAndMobile,
    text: "表示サイズ",
    value: "",
    size: "",
    width: "43px",
    height: "24px",
  },
  {
    icon: iconPc,
    text: "デスクトップ大",
    value: "pc",
    size: "1920px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconPc,
    text: "デスクトップ中",
    value: "laptop",
    size: "1280px",
    width: "30px",
    height: "26px",
  },
  {
    icon: iconMobile,
    text: "タブレット",
    value: "tablet",
    size: "768px",
    width: "20px",
    height: "28px",
  },
  {
    icon: iconMobileSmall,
    text: "スマートフォン",
    value: "smartphone",
    size: "375px",
    width: "14px",
    height: "20px",
  },
];

const savePasswordOptions = [
  {
    text: "保存する",
    value: "save",
  },
  {
    text: "保存しない",
    value: "dont_save",
  }
];

export default class NewMultipleCaptureAkaire extends Component {
  constructor(props) {
    super(props);
    const { id, baseURL, last_page_id, is_create_video_embed, is_create_pass_video, current_total_size } = props;
    this.props = { ...this.props, ...props };
    this.state = {
      isLoading: false,
      loadingText: '',
      urls: [''],
      urlsErrors: [],
      toggleAccountLoginSettings: false,
      toggleAdvancedSettings: false,
      username: '',
      password: "",
      accUsername: "",
      accPassword: "",
      showBasicPassword: false,
      supportSpecialPage: false,
      supportLazyLoad: false,
      showAccPassword: false,
      deviceSelected: deviceOptions[0],
      savePasswordSelected: savePasswordOptions[0],
      shouldShowUrlErrorsModal: false,
      isProcessing: false,
    }
    this.apiClient = apiClient({ baseURL: baseURL || "/" });
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectSavePassword = this.handleSelectSavePassword.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
    this.showPopupCaptureUrl = this.showPopupCaptureUrl.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.getAccountByDomain = this.getAccountByDomain.bind(this);
    this.handleCaptureUrls = this.handleCaptureUrls.bind(this);
  }

  toggleAccountLoginSettings = () => {
    if (!!this.state.toggleAccountLoginSettings) {
      this.setState({ toggleAccountLoginSettings: false });
    } else {
      this.resetAccountLoginSetting();
    }
  };

  toggleAdvancedSettings = () => {
    if (!!this.state.toggleAdvancedSettings) {
      this.setState({ toggleAdvancedSettings: false });
    } else {
      this.resetAdvancedSettings();
    }
  };

  resetAdvancedSettings = () => {
    this.setState({
      toggleAdvancedSettings: true,
      supportSpecialPage: false,
      supportLazyLoad: false,
    });
  };

  resetAccountLoginSetting = () => {
    this.setState({
      toggleAccountLoginSettings: true,
      username: "",
      password: "",
      accUsername: "",
      accPassword: "",
      showBasicPassword: false,
      showAccPassword: false,
    });
  }

  changeUrl = (event, index) => {
    const newUrls = [...this.state.urls];
    newUrls[index] = event.target.value;
    this.setState({ urls: newUrls });
  }

  handleDeleteUrl = (item, index) => {
    const newUrls = [...this.state.urls];
    if (newUrls.length === 1) {
      newUrls[0] = ''
    } else {
      newUrls.splice(index, 1);
    }
    this.setState({ urls: newUrls });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  checkboxChange = (event) => {
    const newUrls = [...this.state.urls];
    if (newUrls.length > MAX_IN_SETTING) {
      Popup.alert(`URLが${MAX_IN_SETTING}個以上追加されたため<br>高度なキャプチャ設定は設定できません。`);
      return
    }
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  getAccountByDomain(event, index) {
    apiClient({
      baseURL: Routes.fetch_account_akaire_page_owner_users_path({ link: event.target.value, task_id: this.props.taskID })
    })
      .post()
      .then((response) =>{
        if (response.data?.success) {
          const data = response.data
          if (!data.basic_password && !data.acc_password && !data.basic_id && !data.acc_id) return

          this.setState({
            username: data.basic_id,
            password: data.basic_password,
            accUsername: data.acc_id,
            accPassword: data.acc_password,
            toggleAccountLoginSettings: true,
          })
        }
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
      })
  }

  addUrl = () => {
    const newUrls = [...this.state.urls];
    const { supportSpecialPage, supportLazyLoad } = this.state;
    if (((supportSpecialPage || supportLazyLoad) && newUrls.length >= MAX_IN_SETTING)) {
      const number = ((supportSpecialPage || supportLazyLoad) && newUrls.length >= MAX_IN_SETTING) ? MAX_IN_SETTING : MAX_URLS
      Popup.alert(`URLが${number}個以上追加されたため<br>高度なキャプチャ設定は設定できません。`);
      return
    }
    if ( newUrls.length >= MAX_URLS) {
      Popup.alert(`1回での追加上限（30個）を超過した為、<br>追加できません。`);
      return
    }
    newUrls.push('');
    this.setState({ urls: newUrls });
  }

  handleSelectSavePassword = (value) => {
    let option = savePasswordOptions.filter((option) => option.value === value);
    option = option.length > 0 ? option[0] : savePasswordOptions[0];
    this.setState({ savePasswordSelected: option })
  }

  fromProjectId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_project_id');
  };
  fromTaskId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_task_id');
  }
  fromWorkId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_work_id');
  }
  fromPageId = () => {
    const url = new URL(location.href);
    return url.searchParams.get('from_page_id');
  }

  toggle = bool => name => {
    this.setState({ [`shouldShow${name}`]: bool });
    if(!bool) {
      if($('#uploadNewPagePopup').attr('data-upload-new') == 'true') {
        $('#uploadNewPagePopup').modal('show')
      } else {
        $('#uploadVersionPopup').modal('show')
      }
    }
  }
  open = this.toggle(true)
  close = this.toggle(false)

  resetUrlErrors = () => {
    this.setState({urlsErrors: []})
  }

  handleCaptureUrls = async (event) => {
    const { isProcessing } = this.state;
    if (isProcessing) return;
    this.setState({ isProcessing: true });

    const newUrls = [...this.state.urls];
    const listErrors = newUrls.filter(url => !regex.test(url));
    let listUrls = newUrls.filter(url => regex.test(url));
    if (listErrors.length > 0) {
      if (listUrls.length === 0) listUrls = ['']
      this.setState({urlsErrors: listErrors, shouldShowUrlErrorsModal: true, urls: listUrls})
      this.open.bind(this, 'UrlErrorsModal')
      this.setState({ isProcessing: false });
    } else {
      const {
        username,
        password,
        accUsername,
        accPassword,
        supportSpecialPage,
        supportLazyLoad,
        deviceSelected,
        savePasswordSelected,
      } = this.state;

      event.preventDefault();
      const data = {
        urls: newUrls,
        acc_username: accUsername,
        acc_password: accPassword,
        username: username,
        password: password,
        supportSpecialPage: supportSpecialPage,
        supportLazyLoad: supportLazyLoad,
        device: deviceSelected.value,
        save_password_auth: savePasswordSelected.value,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      };
      this.screenShotUrl(data)
    }
  }

  screenShotUrl = (data) => {
    const { onFinish } = this.props;
    this.apiClient
      .post("/screen_shot_multiples", data)
      .then((response) => {
        if (response.data?.success == true) {
          const countUrls = this.state.urls.length
          window.location.href =
            Routes.list_temp_file_akaire_feature_akaires_path(
              { capture_all: true, number_file: countUrls }
            );
        } else {
          Popup.alert("現在、このURLは、ご利用できません。");
        }
      })
      .catch(() => {
        Popup.alert("このページにアクセスできません。");
        this.setState({ isProcessing: false });
      })
  }

  showPopupCaptureUrl = (text) =>{
    popupWhenCaptureUrls(text);
  }

  render() {
    const { id, baseURL, last_page_id, is_create_video_embed, is_create_pass_video, current_total_size } = this.props;
    const { loadingText, isLoading, urls, toggleAccountLoginSettings, accUsername, accPassword, showBasicPassword,
            showAccPassword, username, password, savePasswordSelected, toggleAdvancedSettings, supportSpecialPage, supportLazyLoad,
            shouldShowUrlErrorsModal, urlsErrors, isProcessing
          } = this.state;
    const isDisableCapture = urls.filter(url => url !== '').length === 0
    var darkGray = 'l-container u-bg--darkGray py-3 mt-2 mt-sm-0 darkGray-animation';
    return (
      <div className="animation-inspector">
        <Loadable
          className="loading-overlay"
          active={isLoading}
          text={loadingText || 'Now loading...'}
          zIndex={10000}
        >
          <div className={darkGray}>
            <div className="l-main">
              <div className="pane-wrapper">
                {/* アニメーション */}
                <div className='create-akaire-box-multiples text-center create-akaire-multiple-url'>
                  <div className='d-flex text-center justify-content-center align-items-center'>
                    <img className="create-akaire-box-icon-upload filter-black-icon"
                      src={iconCam} alt="アップロード" width="50" />
                    <span className='create-akaire-box-title-content ml-4 mt-1'>WEBサイトのURLを赤入れする</span>
                  </div>
                  <div className="mt-3 align-center-div">
                    <ToggleSectionOfCaptureUrls
                      title="高度なキャプチャ設定が必要なサイト"
                      toggleState={toggleAdvancedSettings}
                      onToggle={this.toggleAdvancedSettings}
                      onHelpClick={() => this.showPopupCaptureUrl('support-special-page-all')}
                    />

                    {toggleAdvancedSettings && (
                      <AdvancedSettings
                        supportSpecialPage={supportSpecialPage}
                        supportLazyLoad={supportLazyLoad}
                        checkboxChange={this.checkboxChange}
                        showPopupCaptureUrl={this.showPopupCaptureUrl}
                      />
                    )}
                  </div>
                  <div className="mb-3 align-center-div">
                    <ToggleSectionOfCaptureUrls
                      title="ID・PASS設定をする"
                      toggleState={toggleAccountLoginSettings}
                      onToggle={this.toggleAccountLoginSettings}
                      onHelpClick={() => this.showPopupCaptureUrl('support-lazy-load-all')}
                    />

                    {toggleAccountLoginSettings && (
                      <AccountLoginSettings
                        accUsername={accUsername}
                        accPassword={accPassword}
                        showAccPassword={showAccPassword}
                        username={username}
                        password={password}
                        showBasicPassword={showBasicPassword}
                        savePasswordSelected={savePasswordSelected}
                        savePasswordOptions={savePasswordOptions}
                        handleChange={this.handleChange}
                        toggleAccPassword={this.toggleAccPassword}
                        toggleBasicPassword={this.toggleBasicPassword}
                        handleSelectSavePassword={this.handleSelectSavePassword}
                        showPopupCaptureUrl={this.showPopupCaptureUrl}
                      />
                    )}
                  </div>
                  <TableUrlToCapture
                    urls={urls}
                    handleDeleteUrl={this.handleDeleteUrl}
                    changeUrl={this.changeUrl}
                    getAccountByDomain={this.getAccountByDomain}
                    addUrl={this.addUrl}
                    handleCaptureUrls={this.handleCaptureUrls}
                    isDisableCapture={isDisableCapture}
                    isProcessing={isProcessing}
                  />
                </div>
              </div>
              {/* / pane-wrapper */}
            </div>
            {/* / l-main */}
          </div>
          {/* / l-container */}
          <ListUrlErrorsUpload isOpen={shouldShowUrlErrorsModal} onClickClose={this.close.bind(this, 'UrlErrorsModal')}
            urls={urlsErrors} resetUrlErrors={this.resetUrlErrors} />
        </Loadable>
      </div>
    );
  }
}

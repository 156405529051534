import React from 'react';
import UploadAkaire from 'images/upload-img.png'
import IconUploadVideo from 'images/upload_video.png';

const UploadSection = ({ handleOnDrop, handleDragLeave, handleDragOver, changeMultipleFiles, showUpload }) => (
  <div className=''>
    <div className='d-flex text-center justify-content-center align-items-center'>
      <img className="create-akaire-box-icon-upload filter-black-icon"
        src={IconUploadVideo} alt="アップロード" width="60" />
      <span className='create-akaire-box-title-content ml-4 mt-1'>動画データをまとめてアップロードする</span>
    </div>
    <div className='d-flex text-center justify-content-center align-items-center mt-2'
         onDrop={handleOnDrop}
         onDragLeave={handleDragLeave} 
         onDragOver={handleDragOver}>
      <label htmlFor="inputFieldFiles" className="form-control-lg input-upload-multiple-files mb-0 cursor-pointer">
        ファイルをここにドラッグ＆ドロップしてください。
      </label>
      <input type='file' id='inputFieldFiles' className='form-control d-none'
        accept='video/mp4,video/mov,video/avi,video/x-ms-wmv,video/quicktime,video/x-msvideo'
        onChange={changeMultipleFiles} multiple />
      <button type='button' className='btn btn-primary ml-3 float-right height-35px' onClick={showUpload}>
        <img className="create-akaire-box-icon-upload"
            src={UploadAkaire} alt="アップロード" width="30" />
        ファイルを選択する
      </button>
    </div>
    <div className='d-flex text-center justify-content-center align-items-center'>
      <span className='text-left small-text-input-file'>※ファイル数によってはアップロードに時間が掛かる場合がございます。</span>
    </div>
  </div>
);

export default UploadSection;
